import React from "react";
import { Container } from "../components/layoutComponents";
import styled from "styled-components";
import portrait from "../images/portrait02.png";
import { openPopupWidget } from "react-calendly";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import iconZoom from "../images/footer-zoom.svg";
import iconPhone from "../images/footer-phone.svg";
import iconEmail from "../images/footer-email.svg";

const H2 = styled.h2`
  margin: 0;
  margin-bottom: 16px;
  font-size: ${({ theme }) => theme.fontSizes.lg};
  color: ${({ theme }) => theme.colors.white};

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: ${({ theme }) => theme.fontSizes.xxl};
    margin-bottom: 40px;
  }
`;

const GetInTouchSection = styled.section`
  color: ${({ theme }) => theme.colors.white};
  background: #000;
  padding: 32px 0 0 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 128px 8px;
    background: linear-gradient(to left, #3c3c3c 50%, #000 50%);
    height: calc(100vh - 100px);
  }
`;

const GetInTouchContainer = styled(Container)`
  width: 100%;
  height: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    display: flex;
  }
`;

const ContentColumn = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding-right: 128px;
    width: 50%;
  }
`;

const ImageColumn = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    display: flex;
    justify-content: flex-start;
    width: 50%;
  }
`;

const Image = styled.img`
  width: calc(100% + 64px);
  margin-left: -32px;
  margin-bottom: -4px;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    height: calc(100% + 256px);
    margin-top: -128px;
    margin-left: 0;
    object-fit: cover;
    position: absolute;
    width: 52vw;
  }
`;

const P = styled.span`
  * {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const List = styled.ul`
  padding: 0;
  list-style-type: none;
  margin: 16px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin: 64px 0;
  }
`;

const ListItem = styled.li`
  display: flex;

  img {
    margin-right: 16px;
  }

  &,
  * {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
    margin: 12px 0;
    padding: 0;
    background: none;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    font-weight: regular;
    outline: none;
    text-decoration: none;
  }

  strong {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;
    transition: all 200ms ease-out;
  }

  &:hover {
    strong {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const CalendlyButton = styled.button`
  background: none;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;

const AboutMe = ({ title, content }) => {
  return (
    <GetInTouchSection>
      <GetInTouchContainer>
        <ContentColumn>
          <div>
            <H2>{title}</H2>
            <P>{documentToReactComponents(JSON.parse(content))}</P>
            <List>
              <ListItem>
                <img role="presentation" loading="lazy" src={iconZoom} />
                <CalendlyButton
                  onClick={() =>
                    openPopupWidget({
                      url: "https://calendly.com/petarceklic/15min",
                    })
                  }
                >
                  Book a <strong>Zoom meeting</strong>
                </CalendlyButton>
              </ListItem>
              <ListItem>
                <img role="presentation" loading="lazy" src={iconEmail} />
                <a href="mailto:hello@petarceklic.com">
                  <strong>Email</strong> me
                </a>
              </ListItem>
              <ListItem>
                <img role="presentation" loading="lazy" src={iconPhone} />
                <a href="tel:0434042628">
                  <strong>Call</strong> me
                </a>
              </ListItem>
            </List>
          </div>
        </ContentColumn>
        <ImageColumn>
          <Image loading="lazy" src={portrait} />
        </ImageColumn>
      </GetInTouchContainer>
    </GetInTouchSection>
  );
};

export default AboutMe;
