import React from "react";
import styled from "styled-components";
import iconOpen from "../images/icon-open-white.svg";
import iconTick from "../images/icon-tick.svg";

const ProjectTopBar = styled.div`
  background: #fff;
  display: block;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    height: 80px;
    display: flex;
  }

  ul {
    background: #fff;
    margin: 0;
    padding: 16px 40px;
    display: flex;
    align-items: center;
    margin-left: -16px;

    @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
      margin-left: 0;
    }

    li {
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      margin: 0;
      padding: 4px 16px 4px 0;
      font-size: 12px;

      @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
        font-size: ${({ theme }) => theme.fontSizes.sm};
        padding-right: 40px;
        flex-wrap: nowrap;
      }

      img {
        margin-right: 8px;

        @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
          margin-left: 0;
        }
      }
    }
  }
`;

const ProjectNameWrapper = styled.div`
  background: ${({ theme }) => theme.colors.black};
  padding: 18px;
  padding-left: 24px;
  position: relative;
  display: flex;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    display: inline-flex;
    padding: 32px;
    width: 400px;
  }

  img {
    width: 14px;
    height: 14px;
    display: inline-block;
    position: relative;
    margin-left: 12px;
    top: 2px;

    @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
      top: 0;
      width: 18px;
      height: 18px;
      margin-left: 22px;
    }
  }

  h3 {
    color: white;
    margin: 0;
    font-weight: 300;
    font-size: ${({ theme }) => theme.fontSizes.xs};

    @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
      font-size: ${({ theme }) => theme.fontSizes.sm};
    }
  }

  a {
    color: white;
    position: relative;
    text-decoration: none;
    display: flex;

    &:hover {
      *,
      & {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

const Slide = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  position: relative;
  height: calc(100vw + 120px);

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    height: calc(60vw - 80px);
    width: 100vw;
  }

  &:after {
    content: "";
    width: 100%;
    bottom: 0;
    background: linear-gradient(
      0deg,
      ${({ theme }) => theme.colors.black},
      transparent
    );
    position: absolute;
    right: 0;
    left: 0;
    z-index: 9;
    height: 256px;
    mix-blend-mode: multiply;
    pointer-events: none;
  }
`;

const Note = styled.p`
  bottom: 68px;
  color: #fff;
  display: table;
  font-size: 12px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 9999;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    bottom: 32px;
    color: #fff;
    font-size: ${({ theme }) => theme.fontSizes.xs};
    left: 32px;
    text-align: center;
    text-align: left;
    width: auto;
  }
`;

const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  pointer-events: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    height: calc(60vw - 160px);
    width: 100vw;
  }

  img {
    width: 100%;
    height: 100%;
    left: 0;
    position: absolute;
    object-fit: cover;
    top: 0;
  }
`;

const WorkSlide = ({
  img,
  noteText,
  projectName,
  service,
  websiteUrl,
  carouselInViewPort,
}) => {
  return (
    <Slide>
      <ProjectTopBar>
        <ProjectNameWrapper>
          {websiteUrl ? (
            <a href={websiteUrl} target="_blank">
              <h3>{projectName}</h3>
              <img loading="lazy" src={iconOpen} />
            </a>
          ) : (
            <h3>{projectName}</h3>
          )}
        </ProjectNameWrapper>
        {service && service.length && (
          <ul>
            {service.map((service, ii) => (
              <li key={`${service}-${ii}`}>
                <img loading="lazy" src={iconTick} />
                {service}
              </li>
            ))}
          </ul>
        )}
      </ProjectTopBar>
      <ImageWrapper>
        <img
          alt={`Portfolio image for ${projectName}`}
          loading={carouselInViewPort ? "eager" : "lazy"}
          src={`https:${img}`}
        />
        {noteText && <Note>{noteText}</Note>}
      </ImageWrapper>
    </Slide>
  );
};

export default WorkSlide;
