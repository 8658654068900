import styled from "styled-components";

export const LightGrayBackground = styled.div`
  background-color: ${({ theme }) => theme.colors.lightestGray};
`;

export const SectionNumber01 = styled.span`
  position: absolute;
  transform: rotate(-90deg);
  margin-left: -48px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 100;
  font-size: 80px;
  opacity: 0.3;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    opacity: 1;
    margin-top: 32px;
    font-size: 256px;
  }
`;

export const SectionNumber02 = styled.span`
  position: absolute;
  transform: rotate(-90deg);
  margin-left: -70px;
  color: ${({ theme }) => theme.colors.lightestGray};
  font-weight: 100;
  font-size: 80px;
  opacity: 0.3;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    opacity: 1;
    margin-top: 32px;
    font-size: 256px;
  }
`;

export const SectionNumber03 = styled.span`
  position: absolute;
  transform: rotate(-90deg);
  margin-left: -70px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 100;
  font-size: 80px;
  opacity: 0.3;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    opacity: 1;
    margin-top: 32px;
    font-size: 256px;
  }
`;

export const SectionNumber04 = styled.span`
  position: absolute;
  transform: rotate(-90deg);
  margin-left: -70px;
  color: ${({ theme }) => theme.colors.blackLight};
  font-weight: 100;
  font-size: 80px;
  opacity: 0.3;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    opacity: 1;
    margin-top: 32px;
    font-size: 256px;
  }
`;
