import React from "react";
import styled from "styled-components";

const ButtonAnchor = styled.button`
  align-items: center;
  background-color: transparent;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  display: inline-flex;
  height: 64px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: auto;
  outline: none;

  &:hover {
    .inner {
      border-color: ${({ theme }) => theme.colors.primary};
      background-color: white;
    }
  }

  .inner {
    align-items: center;
    background-color: transparent;
    border-radius: 100px;
    border: 2px solid ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.black};
    display: inline-flex;
    font-size: 16px;
    font-weight: bold;
    height: 64px;
    left: 0;
    line-height: 14px;
    overflow: hidden;
    padding: 24px;
    position: relative;
    text-transform: lowercase;
    top: 0;
    transition: 200ms;
    width: 100%;

    &:hover {
      transition: 0.2s 0.1s;
    }
  }
`;

const Button = ({ children, onClick, alt }) => {
  return (
    <ButtonAnchor onClick={onClick} alt={alt}>
      <span className="inner">{children}</span>
    </ButtonAnchor>
  );
};

export default Button;
