import styled, { css } from "styled-components";
import lineBg from "../images/line-bg.png";

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;
  max-width: 100vw;
  overflow-x: hidden;
  padding-top: 8px;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding-top: 64px;
  }
`;

export const Container = styled.div`
  margin: 0 auto;
  padding: 0 24px;
  max-width: 1600px;
  width: 100%;
  position: relative;

  ${({ lines }) =>
    lines &&
    css`
      background-position: center;
      background-image: url("${lineBg}");
      background-size: 100%;

      @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
        max-width: 1472px;
      }
    `};

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 0 64px;
  }
`;
