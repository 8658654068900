import React from "react";
import { Container } from "../components/layoutComponents";
import styled from "styled-components";
import iconTick from "../images/icon-tick.svg";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const breakpoint = "1100px";

const ServicesContainer = styled(Container)`
  position: relative;

  @media (min-width: ${breakpoint}) {
    padding-bottom: 64px;
    padding-top: 64px;
  }
`;

const Heading = styled.h2`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.xl};
  margin-bottom: 16px;

  @media (min-width: ${breakpoint}) {
    font-size: ${({ theme }) => theme.fontSizes.xxl};
    margin-bottom: 24px;
  }
`;

const Content = styled.p`
  text-align: center;
  max-width: 100%;
  margin: 0 auto;

  @media (min-width: ${breakpoint}) {
    width: 820px;
  }
`;

const ServiceColumns = styled.div`
  text-align: center;
  padding: 64px 0 0;
  position: relative;

  @media (min-width: ${breakpoint}) {
    display: flex;
    justify-content: space-between;
    padding: 80px 0 64px 0;
  }
`;

const ServiceItem = styled.div`
  margin-bottom: 40px;

  @media (min-width: ${breakpoint}) {
    margin-bottom: 0;
    width: 31%;
  }
`;

const ServiceIcon = styled.img`
  width: 64px;
  height: 64px;
  margin: 0 auto;
`;

const ServiceName = styled.div`
  font-size: 32px;
  font-weight: bold;
  margin: 16px auto;
`;

const ServiceDescription = styled.div`
  font-size: 16px;
  margin-bottom: 16px;

  @media (min-width: ${breakpoint}) {
    margin-bottom: 32px;
  }
`;

const ServiceSubList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0 auto;
`;

const ServiceSubListItem = styled.div`
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.mediumGray};
  padding: 4px 8px;
  margin: 4px;
  font-size: ${({ theme }) => theme.fontSizes.xxs};

  img {
    margin-right: 6px;
  }

  @media (min-width: ${breakpoint}) {
    padding: 6px 14px 6px 10px;
    margin: 4px;
  }
`;

const Services = ({ title, content, services }) => {
  return (
    <section>
      <ServicesContainer>
        <div>
          <Heading>{title}</Heading>
          <Content>{documentToReactComponents(JSON.parse(content))}</Content>
        </div>
        <ServiceColumns>
          {services.map((service) => {
            return (
              <ServiceItem>
                <ServiceIcon
                  loading="lazy"
                  role="presentation"
                  src={`https:${service.image.file.url}`}
                />
                <ServiceName>{service.title}</ServiceName>
                <ServiceDescription>{service.description}</ServiceDescription>
                <ServiceSubList>
                  {service.listItem.map((item) => {
                    return (
                      <ServiceSubListItem>
                        <img
                          loading="lazy"
                          role="presentation"
                          src={iconTick}
                        />
                        {item}
                      </ServiceSubListItem>
                    );
                  })}
                </ServiceSubList>
              </ServiceItem>
            );
          })}
        </ServiceColumns>
      </ServicesContainer>
    </section>
  );
};

export default Services;
