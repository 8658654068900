import React from "react";
import { Container } from "../components/layoutComponents";
import styled from "styled-components";
import quoteIcon from "../images/quote.svg";

const QuoteContainer = styled(Container)`
  position: relative;
  padding-bottom: 8px;
  padding-top: 48px;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  > div {
    background: ${({ theme }) => theme.colors.lighterGray};

    @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
      display: flex;
      justify-content: space-between;
      padding: 32px;
    }
  }
`;

const QuoteBox = styled.div`
  padding: 24px;
  margin-bottom: 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: calc(50% - 16px);
    margin-bottom: 0;
    padding: 32px;
  }
`;

const Quote = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: 1.5;
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 32px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    min-height: 172px;
    font-size: ${({ theme }) => theme.fontSizes.md};
    min-height: 128px;
  }
`;

const DetailsWrapper = styled.div`
  display: flex;
`;

const Photo = styled.img`
  margin-right: 16px;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
`;

const Name = styled.div`
  font-size: 14px;
  margin-bottom: 6px;
`;

const TextWrapper = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;
`;

const Company = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const QuoteIcon = styled.img`
  position: absolute;
  top: 0px;
  left: 50%;
  width: 64px;
  transform: translateX(-50%);

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    top: 32px;
  }
`;

const Quotes = ({ testimonials }) => {
  return (
    <section>
      <QuoteContainer lines>
        <QuoteIcon src={quoteIcon} role="presentation" />
        <div>
          {testimonials.map((testimonial) => (
            <QuoteBox>
              <Quote>{testimonial.quote.quote}</Quote>
              <DetailsWrapper>
                <Photo
                  alt={`Photo of ${testimonial.name}`}
                  src={`https:${testimonial.image.file.url}`}
                  loading="lazy"
                />
                <TextWrapper>
                  <Name>{testimonial.name}</Name>
                  <Company>{testimonial.company}</Company>
                </TextWrapper>
              </DetailsWrapper>
            </QuoteBox>
          ))}
        </div>
      </QuoteContainer>
    </section>
  );
};

export default Quotes;
