import React from "react";
import { Container } from "../components/layoutComponents";
import styled from "styled-components";
import about from "../images/about.png";
import gifBananas from "../images/bananas.gif";
import gifCoffee from "../images/coffee.gif";
import gifContainer from "../images/container.gif";
import iconCoffee from "../images/icon-coffee.svg";
import iconHome from "../images/icon-home.svg";
import iconOpen from "../images/icon-open.svg";
import iconRunning from "../images/icon-run.svg";
import iconShirt from "../images/icon-shirt.svg";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const H2 = styled.h2`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes.lg};
  position: relative;
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: ${({ theme }) => theme.fontSizes.xxl};
    margin-bottom: 40px;
  }

  &:before {
    content: "";
    background: ${({ theme }) => theme.colors.primary};
    position: absolute;
    width: 4px;
    height: 74px;
    top: 0;
    left: -24px;

    @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
      left: -33px;
    }
  }
`;

const AboutMeContainer = styled(Container)`
  display: flex;
  width: 100%;
`;

const ContentColumn = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  padding: 32px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 50%;
    padding: 128px 0 64px 32px;
  }
`;

const ImageColumn = styled.div`
  width: 50%;
  justify-content: flex-start;
  display: none;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    display: flex;
    padding: 128px 32px 0 0;
    margin-bottom: 64px;
  }
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  mix-blend-mode: multiply;
  object-fit: contain;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    height: 100%;
    left: -80%;
  }

  @media (min-width: 1300px) {
    bottom: 0;
    height: auto;
    left: auto;
    top: auto;
    width: 100%;
  }
`;

const List = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    flex-wrap: nowrap;
  }
`;

const ListItem = styled.li`
  display: flex;
  list-style-type: none;
  margin-bottom: 16px;
  margin-right: 32px;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  position: relative;

  a {
    &:hover {
      text-decoration-color: ${({ theme }) => theme.colors.primary};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    a {
      &:hover {
        img {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin-right: 20px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    white-space: nowrap;
  }

  @media (min-width: 1620px) {
    margin-right: 32px;
  }
`;

const IconLeft = styled.img`
  margin-right: 8px;
`;

const IconRight = styled.img`
  margin-left: 8px;
`;

const H4 = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.md};
  margin-bottom: 12px;
`;

const Gif = styled.img`
  position: absolute;
  width: 220px;
  height: auto;
  top: -138px;
  opacity: 0;
  transform: translateY(-16px);
  pointer-events: none;
  transition: all 150ms ease-out;
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: block;
  }
`;

const AboutMe = ({ title, content }) => {
  return (
    <section>
      <AboutMeContainer lines>
        <ImageColumn>
          <Image loading="lazy" src={about} />
        </ImageColumn>
        <ContentColumn>
          <div style={{ width: "100%" }}>
            <div>
              <H2>{title}</H2>
              <div>{documentToReactComponents(JSON.parse(content))}</div>
            </div>
            <div>
              <H4>Interests</H4>
              <List>
                <ListItem>
                  <IconLeft loading="lazy" src={iconCoffee} /> Coffee
                </ListItem>
                <ListItem>
                  <IconLeft loading="lazy" src={iconRunning} /> Running
                </ListItem>
                <ListItem>
                  <IconLeft loading="lazy" src={iconHome} /> Tiny homes
                </ListItem>
                <ListItem>
                  <IconLeft loading="lazy" src={iconShirt} /> Wearing black
                </ListItem>
              </List>
            </div>
            <div>
              <H4>15 minutes of fame</H4>
              <List>
                <ListItem>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/watch?v=6IqwmAwGOcY"
                  >
                    <Gif
                      loading="lazy"
                      role="presentation"
                      src={gifContainer}
                    />
                    1.3 million views on a video{" "}
                    <IconRight loading="lazy" src={iconOpen} />
                  </a>
                </ListItem>
                <ListItem>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/watch?v=gognueSd0b4"
                  >
                    <Gif loading="lazy" role="presentation" src={gifCoffee} />
                    Today Tonight feature{" "}
                    <IconRight loading="lazy" src={iconOpen} />
                  </a>
                </ListItem>
                <ListItem>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/watch?v=QCeeWeKRwVs"
                  >
                    <Gif loading="lazy" role="presentation" src={gifBananas} />
                    Eating 30 bananas in a sitting
                    <IconRight loading="lazy" src={iconOpen} />
                  </a>
                </ListItem>
              </List>
            </div>
          </div>
        </ContentColumn>
      </AboutMeContainer>
    </section>
  );
};

export default AboutMe;
