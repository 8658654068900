import React from "react";
import { Container } from "../components/layoutComponents";
import styled from "styled-components";
import icon1 from "../images/icon-big-01.svg";
import icon2 from "../images/icon-big-02.svg";
import icon3 from "../images/icon-big-03.svg";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const icons = {
  circle: icon1,
  plus: icon2,
  square: icon3,
};

const Section = styled.section`
  padding: 32px 0;
`;

const StyledContainer = styled(Container)`
  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    display: flex;
    flex-direction: ${({ align }) =>
      align === "left" ? "row" : "row-reverse"};
    justify-content: space-between;
  }
`;

const Icon = styled.img`
  height: 220px;
  width: 220px;
  margin-top: -160px;
  position: relative;
  top: 160px;
  opacity: 0.1;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin-top: 0;
    top: auto;
    opacity: 1;
    width: 520px;
    height: 520px;
  }
`;

const IconRow = styled.div`
  display: flex;
  justify-content: ${({ align }) =>
    align === "left" ? "flex-start" : "flex-end"};

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 0 32px;
    width: 50%;
  }
`;

const ContentRow = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    display: flex;
    align-items: center;
    max-width: 100%;
    padding: 0 32px;
    width: 50%;
    max-width: 768px;
  }
`;

const H3 = styled.h3`
  margin-bottom: 24px;
  max-width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.xl};

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 720px;
    font-size: ${({ theme }) => theme.fontSizes.xxl};
  }
`;

const Content = styled.div`
  margin-bottom: 32px;
`;

const A = styled.a`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: bold;
  text-decoration: none;
  position: relative;
  display: inline-block;

  &:hover {
    &:after {
      transform: translateY(2px);
    }
  }

  &:after {
    content: "";
    width: 100%;
    height: 4px;
    display: block;
    background-color: ${({ theme }) => theme.colors.primary};
    margin-top: -4px;
    transition: all 200ms ease-out;
  }
`;

const IconContent = ({ align = "left", icon, title, content, cta }) => {
  return (
    <Section>
      <StyledContainer align={align}>
        <IconRow align={align}>
          <Icon loading="lazy" src={icons[icon]} />
        </IconRow>
        <ContentRow>
          <div style={{ width: "100%" }}>
            <H3>{title}</H3>
            <Content>{documentToReactComponents(JSON.parse(content))}</Content>
            <A
              href="#contact"
              onClick={() =>
                document.getElementById("contact").scrollIntoView()
              }
            >
              {cta}
            </A>
          </div>
        </ContentRow>
      </StyledContainer>
    </Section>
  );
};

export default IconContent;
