import React, { useEffect, useState } from "react";
import { Container } from "../components/layoutComponents";
import Button from "../components/Button";
import styled, { css, keyframes } from "styled-components";
import logo from "../images/logo.svg";
import coffeeIcon from "../images/icon-coffee.svg";
import { useScrollDirection } from "react-use-scroll-direction";

const fadeDown = keyframes`
  from {
    transform: translateY(-32px);
    opacity :0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Header = styled.header`
  height: 64px;
  opacity: 0;
  background-color: ${({ scrolled }) => (scrolled ? "white" : "transparent")};
  transform: translateY(-32px);
  will-change: transform;
  animation: ${fadeDown};
  animation-iteration-count: 1;
  animation-delay: 600ms;
  animation-duration: 1s;
  animation-fill-mode: forwards;

  ${({ isHidden }) =>
    isHidden
      ? css`
          transform: translateY(-32px);
          opacity: 0;
        `
      : css`
          transform: translateY(0px);
          opacity: 1;
        `};

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 16px 0;
    height: 82px;
    padding-top: ${({ scrolled }) => (scrolled ? "16px" : "64px")};
  }
`;

const Wrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;

    ${({ isHidden }) =>
      isHidden
        ? css`
            transform: translateY(-32px);
            pointer-events: none;
            opacity: 0;
          `
        : css`
            transform: translateY(0);
            opacity: 1;
          `};
  }
`;

const Logo = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

const Navigation = styled.nav`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    display: flex;
  }
`;

const HeaderContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  will-change: transform;
  animation: ${fadeDown};
  animation-iteration-count: 1;
  animation-delay: 600ms;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
`;

const Link = styled.button`
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
  height: 64px;
  padding: 24px 48px;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;

  &:hover {
    span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const Number = styled.span`
  color: ${({ theme }) => theme.colors.lightGray};
  font-weight: 200;
  margin-right: 8px;
  display: inline-block;
`;

const ButtonImg = styled.img`
  margin-right: 8px;
  display: inline-flex;
`;

const LogoButton = styled.button`
  outline: none;
  background: none;
  border: none;
  padding: 0;
  cursor: ${({ scrolled }) => (scrolled ? "pointer" : "default")};
  transform: scale(1);
  transform-style: preserve-3d;
  width: 42px;
  height: 42px;
  display: block;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: ${({ scrolled }) => (scrolled ? "50px" : "80px")};
    height: ${({ scrolled }) => (scrolled ? "50px" : "80px")};
  }

  ${({ scrolled }) =>
    scrolled &&
    css`
      &:hover {
        transform: scale(1.1);
      }
    `};
`;

const ButtonText = styled.span`
  display: inline-flex;
`;

const HeaderComponent = () => {
  const [scrolled, setScrolled] = useState();
  const [isHidden, setHidden] = useState(false);
  const { isScrollingUp, isScrollingDown } = useScrollDirection();
  if (isScrollingUp) setHidden(false);
  if (isScrollingDown) setHidden(true);

  const onScroll = (e) => {
    if (e.target.documentElement.scrollTop < 24) {
      setScrolled(false);
    } else {
      if (!scrolled && e.target.documentElement.scrollTop >= 24) {
        setScrolled(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <Wrapper isHidden={isHidden && scrolled}>
      <Header scrolled={scrolled}>
        <HeaderContainer>
          <LogoButton
            scrolled={scrolled}
            onClick={() => {
              if (scrolled) document.getElementById("top").scrollIntoView();
            }}
          >
            <Logo scrolled={scrolled} src={logo} />
          </LogoButton>
          <Navigation>
            <Link
              onClick={() => document.getElementById("about").scrollIntoView()}
            >
              <Number>01</Number>about
            </Link>
            <Link
              onClick={() =>
                document.getElementById("services").scrollIntoView()
              }
            >
              <Number>02</Number>services
            </Link>
            <Link
              onClick={() => document.getElementById("work").scrollIntoView()}
            >
              <Number>03</Number>work
            </Link>
            <Button
              onClick={() =>
                document.getElementById("contact").scrollIntoView()
              }
              alt={!scrolled}
            >
              <ButtonImg src={coffeeIcon} alt="Coffee Cup" />
              <ButtonText>get in touch</ButtonText>
            </Button>
          </Navigation>
        </HeaderContainer>
      </Header>
    </Wrapper>
  );
};

export default HeaderComponent;
