import { createGlobalStyle } from "styled-components";
import "normalize.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "NeurialGrotesk";
    src: url("../fonts/Neurial Grotesk Light.ttf") format("truetype");
    font-display: block;
  }

  @font-face {
    font-family: "NeurialGrotesk";
    src: url("../fonts/Neurial Grotesk Bold.ttf") format("truetype");
    font-weight: bold;
    font-display: block;
  }

  * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: "NeurialGrotesk";

    ::selection {
      background: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  html {
      height:100%;
      min-height:100%;
      scroll-behavior: smooth;
  }

  body {
      margin: 0;
      background: #fff;
      height: 100%;
      min-height: 100%;
      word-break: break-word;
      font-family: "NeurialGrotesk", Helvetica, sans-serif !important;

      * {
        color: ${({ theme }) => theme.colors.black};
        transition: all 200ms ease-out;
      }

  }

  p {
    line-height: 1.6;
    font-size: ${({ theme }) => theme.fontSizes.xs};
    margin-top: 0;
    margin-bottom: 24px;

    *:last-child {
      margin-bottom: 0;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
        font-size: ${({ theme }) => theme.fontSizes.sm};
    }
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;
    }
  }

  *::marker {
    display: none;  
  }

  .slick-slider {
    touch-action: auto !important;
    -ms-touch-action: auto;
  }
`;

export default GlobalStyle;
