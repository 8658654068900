import React from "react";
import { MainWrapper } from "../components/layoutComponents";
import Header from "../components/Header";
import Banner from "../components/Banner";
import BrandCarousel from "../components/BrandCarousel";
import AboutMe from "../components/AboutMe";
import Stats from "../components/Stats";
import Quotes from "../components/Quotes";
import Services from "../components/Services";
import IconContent from "../components/IconContent";
import WorkIntro from "../components/WorkIntro";
import WorkCarousel from "../components/WorkCarousel";
import GetInTouch from "../components/GetInTouch";
import Footer from "../components/Footer";
import Theme from "../components/Theme";
import GlobalStyle from "../components/GlobalStyle";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";
import { useInViewport } from "ahooks";
import {
  LightGrayBackground,
  SectionNumber01,
  SectionNumber02,
  SectionNumber03,
  SectionNumber04,
} from "../components/styles";

const IndexPage = ({
  data: {
    contentfulHomePage: {
      aboutMeContent,
      aboutMeTitle,
      bannerButtonText,
      bannerContent,
      bannerTitle,
      brandCarouselImages,
      brandCarouselTitle,
      contentSectionOneContent,
      contentSectionOneCta,
      contentSectionOneTitle,
      contentSectionThreeContent,
      contentSectionThreeCta,
      contentSectionThreeTitle,
      contentSectionTwoContent,
      contentSectionTwoCta,
      contentSectionTwoTitle,
      footerContent,
      footerTitle,
      seoMetaDescription,
      seoOpenGraphImage,
      seoTitle,
      services,
      servicesContent,
      servicesTitle,
      testimonials,
      workCarousel,
      workContent,
      workImage,
      workTitle,
    },
  },
}) => {
  const carouselInViewPort = useInViewport(() =>
    typeof window === "undefined"
      ? false
      : document.getElementById("work-carousel")
  );

  return (
    <Theme>
      <MainWrapper id="wrapper">
        <Helmet
          title={seoTitle}
          meta={[
            {
              name: `description`,
              content: seoMetaDescription,
            },
            {
              property: `og:title`,
              content: seoTitle,
            },
            {
              property: `og:description`,
              content: seoMetaDescription,
            },
            {
              property: `og:image`,
              content: `https:${seoOpenGraphImage.file.url}`,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
            {
              name: `twitter:title`,
              content: seoTitle,
            },
            {
              name: `twitter:description`,
              content: seoMetaDescription,
            },
          ]}
        />
        <GlobalStyle />
        <Header />
        <Banner
          title={bannerTitle}
          content={bannerContent}
          buttonText={bannerButtonText}
        />
        <Fade ssrReveal delay={1200} distance={"64px"} bottom>
          <BrandCarousel
            title={brandCarouselTitle}
            images={brandCarouselImages}
          />
        </Fade>
        <div id="about">
          <Fade ssrReveal delay={1400} distance={"64px"} bottom>
            <LightGrayBackground>
              <div>
                <SectionNumber01>01</SectionNumber01>
                <AboutMe title={aboutMeTitle} content={aboutMeContent.raw} />
              </div>
              <Fade distance={"64px"} bottom>
                <Stats />
              </Fade>
              <Fade distance={"64px"} bottom>
                <Quotes testimonials={testimonials} />
              </Fade>
            </LightGrayBackground>
          </Fade>
        </div>
        <div id="services">
          <SectionNumber02>02</SectionNumber02>
          <Fade distance={"64px"} bottom>
            <Services
              title={servicesTitle}
              content={servicesContent.raw}
              services={services}
            />
          </Fade>
        </div>
        <div style={{ paddingBottom: "64px" }}>
          <Fade distance={"64px"} left>
            <IconContent
              align="left"
              icon="circle"
              title={contentSectionOneTitle}
              content={contentSectionOneContent.raw}
              cta={contentSectionOneCta}
            />
          </Fade>
          <Fade distance={"64px"} right>
            <IconContent
              align="right"
              icon="plus"
              title={contentSectionThreeTitle}
              content={contentSectionThreeContent.raw}
              cta={contentSectionTwoCta}
            />
          </Fade>
          <Fade distance={"64px"} left>
            <IconContent
              align="left"
              icon="square"
              title={contentSectionTwoTitle}
              content={contentSectionTwoContent.raw}
              cta={contentSectionThreeCta}
            />
          </Fade>
        </div>
        <Fade distance={"64px"} right>
          <div id="work">
            <LightGrayBackground>
              <SectionNumber03>03</SectionNumber03>
              <WorkIntro
                title={workTitle}
                content={workContent.raw}
                image={workImage.file.url}
              />
            </LightGrayBackground>
          </div>
        </Fade>
        <div id="work-carousel">
          <WorkCarousel
            carouselInViewPort={carouselInViewPort}
            workCarousel={workCarousel}
          />
        </div>
        <div id="contact">
          <SectionNumber04>04</SectionNumber04>
          <GetInTouch title={footerTitle} content={footerContent.raw} />
        </div>
        <Footer />
      </MainWrapper>
    </Theme>
  );
};

export const query = graphql`
  {
    contentfulHomePage {
      bannerTitle
      bannerContent
      bannerButtonText
      aboutMeTitle
      brandCarouselTitle
      brandCarouselImages {
        file {
          url
        }
      }
      aboutMeContent {
        raw
      }
      contentSectionOneContent {
        raw
      }
      contentSectionOneTitle
      contentSectionThreeContent {
        raw
      }
      contentSectionThreeTitle
      contentSectionTwoContent {
        raw
      }
      contentSectionTwoTitle
      contentSectionOneCta
      contentSectionTwoCta
      contentSectionThreeCta
      footerContent {
        raw
      }
      footerTitle
      servicesTitle
      servicesContent {
        raw
      }
      services {
        title
        description
        listItem
        image {
          file {
            url
          }
        }
      }
      workTitle
      workContent {
        raw
      }
      workCarousel {
        projectName
        service
        websiteUrl
        noteText
        carouselImages {
          file {
            url
          }
        }
        carouselImagesMobile {
          file {
            url
          }
        }
      }
      workImage {
        file {
          url
        }
      }
      testimonials {
        name
        company
        image {
          file {
            url
          }
        }
        quote {
          quote
        }
      }
      seoTitle
      seoMetaDescription
      seoOpenGraphImage {
        file {
          url
        }
      }
    }
  }
`;

export default IndexPage;
