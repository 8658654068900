import React, { useEffect, useState } from "react";
import { Container } from "../components/layoutComponents";
import styled from "styled-components";
import Slider from "react-slick";

const MobileSlideWrapper = styled.div`
  height: 60px;
  position: relative;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const MobileSlideImage = styled.img`
  padding-right: 20px;
  object-fit: contain;
  position: absolute;
  height: 100%;
  width: 100%;
`;

const Text = styled.h3`
  position: absolute;
  top: -30px;
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  padding: 8px;
  margin-left: -8px;
  background: white;
  font-weight: 200;
  padding-top: 32px;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding-top: 0;
  }
`;

const LogoSectionTitle = styled(Container)`
  padding-bottom: 16px;
  padding-top: 32px;
`;

const MobileSliderContainer = styled(Container)`
  padding-bottom: 8px;
  position: relative;
  display: block;

  .slick-list {
    &:after {
      content: "";
      height: 100%;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(45deg, rgba(255, 255, 255, 0), white);
      display: block;
      position: absolute;
      width: 128px;

      @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
        width: 256px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    display: none;
  }
`;

const DesktopSliderContainer = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    display: block;
    padding-bottom: 50px;
    overflow: hidden;
    max-width: 100vw;
  }
`;

const DesktopSliderTrack = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-left: calc(((100vw - 1600px) / 2) + 64px);
`;

const DesktopSliderImage = styled.img`
  width: auto;
  height: 52px;
  margin-right: 98px;
`;

const BrandCarousel = ({ title, images }) => {
  const [scrollPosition, setScrollPosition] = useState();
  const onScroll = () => {
    const scrollTop =
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop;
    setScrollPosition(scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <section>
      <LogoSectionTitle>
        <Text>{title}</Text>
      </LogoSectionTitle>
      {/* MOBILE SLIDER */}
      <MobileSliderContainer>
        <Slider
          dots={false}
          infinite={true}
          speed={500}
          autoplay={true}
          slidesToShow={4}
          slidesToScroll={1}
          initialSlide={0}
          autoplaySpeed={2000}
          cssEase="ease-out"
        >
          {[...images, ...images, ...images].map((image) => (
            <MobileSlideWrapper>
              <MobileSlideImage
                loading="lazy"
                role="presentation"
                src={`https:${image.file.url}`}
              />
            </MobileSlideWrapper>
          ))}
        </Slider>
      </MobileSliderContainer>
      {/* DESKTOP SLIDER */}
      <DesktopSliderContainer>
        <DesktopSliderTrack
          style={{
            transform: `translateX(-${scrollPosition * 1.5}px)`,
          }}
        >
          {[...images, ...images, ...images].map((image) => (
            <DesktopSliderImage
              loading="lazy"
              role="presentation"
              src={`https:${image.file.url}`}
            />
          ))}
        </DesktopSliderTrack>
      </DesktopSliderContainer>
    </section>
  );
};

export default BrandCarousel;
