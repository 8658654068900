import React, { useMemo } from "react";
import { Container } from "../components/layoutComponents";
import styled from "styled-components";
import Fade from "react-reveal/Fade";

const StyledContainer = styled(Container)`
  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 0;
  }
`;

const Wrapper = styled.div`
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 24px;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding-bottom: 64px;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid black;
  margin-bottom: 24px;
  padding: 24px;
  width: 50%;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin-bottom: 0;
    padding: 0 32px 0 32px;
    width: 25%;
  }
`;

const Number = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  padding-bottom: 12px;
  font-weight: bold;
  margin-top: -8px;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin-bottom: 0;
    font-size: ${({ theme }) => theme.fontSizes.xxl};
  }
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.black};
`;

const Stats = () => {
  const currentYear = new Date().getFullYear();
  const yearsSince2020 = currentYear - 2020;

  const yearsInIndustry = useMemo(() => {
    return 21 + yearsSince2020;
  }, []);

  const projectsCompleted = useMemo(() => {
    return 131 + yearsSince2020 * 20;
  }, []);

  const coffeeDrankInYear = useMemo(() => {
    const firstDayOfYear = new Date(currentYear, 0, 1);
    const todayDate = new Date();
    const numberOfDaysPassed = Math.ceil((todayDate - firstDayOfYear) / 8.64e7); // 31
    return numberOfDaysPassed * 3;
  }, []);

  const artBoardsCreated = useMemo(() => {
    const firstDayOfYear = new Date(currentYear, 0, 1);
    const todayDate = new Date();
    const numberOfDaysPassed = Math.ceil((todayDate - firstDayOfYear) / 8.64e7); // 31
    return 1000 + yearsSince2020 * 365 + numberOfDaysPassed;
  }, []);

  return (
    <section>
      <StyledContainer lines>
        <Wrapper>
          <Item>
            <Fade delay={0} distance={"64px"} bottom>
              <Number>{yearsInIndustry}</Number>
              <Text>Years in the industry</Text>
            </Fade>
          </Item>
          <Item>
            <Fade delay={200} distance={"64px"} bottom>
              <Number>{projectsCompleted}</Number>
              <Text>Projects completed</Text>
            </Fade>
          </Item>
          <Item>
            <Fade delay={400} distance={"64px"} bottom>
              <Number>{coffeeDrankInYear}</Number>
              <Text>Coffees drank for {currentYear}</Text>
            </Fade>
          </Item>
          <Item>
            <Fade delay={600} distance={"64px"} bottom>
              <Number>{artBoardsCreated}</Number>
              <Text>Artboards created</Text>
            </Fade>
          </Item>
        </Wrapper>
      </StyledContainer>
    </section>
  );
};

export default Stats;
