import React from "react";
import { Container } from "../components/layoutComponents";
import styled, { keyframes, css } from "styled-components";
import beans1 from "../images/beans01.png";
import beans2 from "../images/beans02.png";
import portrait from "../images/portrait01.png";
import dots from "../images/dots.svg";
import arrow from "../images/icon-arrow.png";
import Button from "../components/Button";
import Tilt from "react-parallax-tilt";

const levitate = keyframes`
    55% {
        transform: translateY(-10%);
    }
`;

const fadeUp = keyframes`
  from {
    transform: translateY(32px);
    opacity:0;
  }

  to {
    transform: translateY(0);
    opacity:1;
  }
`;

const drawLine = keyframes`
  from {
    transform: translateX(50%) translateY(-50%) rotate(-45deg) scaleX(0);
    opacity:0;
  }

  to {
    transform: translateX(50%) translateY(-50%) rotate(-45deg) scaleX(1);
    opacity:1;
  }
`;

const Section = styled.section`
  position: relative;
`;

const Dots = styled.img`
  height: 112px;
  width: 112px;
  position: absolute;
  bottom: 0;
  left: 156px;
  will-change: transform;
  animation-delay: 1s;
  animation: ${levitate} 12s infinite ease-in-out alternate;
`;

const H1 = styled.h1`
  font-weight: bold;
  margin: 0;
  white-space: nowrap;
  margin-bottom: 16px;
  font-size: ${({ theme }) => theme.fontSizes.xl};
  z-index: 99;
  position: relative;
  opacity: 0;
  will-change: transform;
  animation: ${fadeUp};
  animation-iteration-count: 1;
  animation-delay: 900ms;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 24px;
    font-size: ${({ theme }) => theme.fontSizes.xxl};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    margin-bottom: 32px;
    font-size: ${({ theme }) => theme.fontSizes.xxxxl};
  }
`;

const Banner = styled(Container)`
  display: flex;
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightestGray};
  width: 100%;
  flex-direction: column-reverse;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    flex-direction: row;
    padding-top: 96px;
    padding-bottom: 128px;
  }
`;

const ContentColumn = styled.div`
  display: flex;
  align-items: center;
  padding-top: 32px;

  > div {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding-right: 5%;
    width: 50%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding-right: 0;
  }
`;

const P = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  position: relative;
  will-change: transform;
  z-index: 99;
  will-change: transform;
  animation: ${fadeUp};
  animation-iteration-count: 1;
  animation-delay: 1200ms;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: ${({ theme }) => theme.fontSizes.md};
  }
`;

const ImageColumn = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 256px;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    padding-bottom: 32px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    justify-content: flex-end;
    padding-bottom: 0;
    width: 50%;
  }
`;

const Image = styled.img`
  border-radius: 50%;
  width: 80vw;
  height: 80vw;
  mix-blend-mode: multiply;
  will-change: transform;
  animation: ${fadeUp};
  animation-iteration-count: 1;
  animation-delay: 300ms;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 50vw;
    height: 50vw;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    width: 612px;
    height: 612px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
  will-change: transform;
  animation: ${fadeUp};
  animation-iteration-count: 1;
  animation-delay: 1400ms;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 48px;
  }
`;

const Line = styled.span`
  background: ${({ theme }) => theme.colors.primary};
  display: block;
  mix-blend-mode: hard-light;
  opacity: 1;
  position: absolute;
  right: calc(50% - 32px);
  top: calc(50% - 32px);
  transform-origin: center;
  transform: translateX(50%) translateY(-50%) rotate(-45deg);
  height: 20vw;
  width: 140vw;
  opacity: 0;
  will-change: transform;
  animation: ${drawLine};
  animation-iteration-count: 1;
  animation-delay: 600ms;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    height: 20vw;
    width: 100vw;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    right: calc(50% - 140px);
    top: calc(50% - 120px);
    height: 200px;
    width: 1500px;
  }
`;

const Bean = styled.div`
  height: auto;
  mix-blend-mode: multiply;
  position: absolute;
  will-change: transform;
  animation: ${fadeUp};
  animation-iteration-count: 1;
  animation-delay: 1400ms;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;

  img {
    width: 100%;
  }

  ${({ left }) =>
    left &&
    css`
      bottom: 10%;
      left: -10vw;
      max-width: 290px;
      position: absolute;
      width: 30vw;
      animation-delay: 900ms;

      img {
        animation: ${levitate} 12s infinite ease-in-out alternate;
        animation-delay: 1s;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        left: -80px;
        bottom: 15%;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
        width: 20vw;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        bottom: 100px;
        left: -80px;
      }
    `};

  ${({ right }) =>
    right &&
    css`
      bottom: 10%;
      max-width: 290px;
      right: -10vw;
      width: 30vw;
      animation-delay: 1100ms;

      img {
        animation: ${levitate} 12s infinite ease-in-out alternate;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        right: -110px;
        bottom: 10%;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
        width: 20vw;
        right: -80px;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        bottom: 0;
        right: -110px;
        width: 320px;
      }
    `};
`;

const ArrowIcon = styled.img`
  margin-left: 8px;
`;

const BannerComponent = ({ title, content, buttonText }) => {
  return (
    <Section>
      <Banner>
        <ContentColumn>
          <div>
            <H1 dangerouslySetInnerHTML={{ __html: title }} />
            <P>{content}</P>
            <ButtonWrapper>
              <Button
                onClick={() => document.getElementById("work").scrollIntoView()}
              >
                {buttonText}
                <ArrowIcon src={arrow} alt="" role="presentation" />
              </Button>
            </ButtonWrapper>
          </div>
        </ContentColumn>
        <ImageColumn>
          <Tilt
            reset
            gyroscope
            trackOnWindow
            tiltMaxAngleX={5}
            tiltMaxAngleY={5}
            perspective={2000}
          >
            <div className="inner-element">
              <Line />
              <Bean left>
                <img alt="Coffee Bean" src={beans2} />
              </Bean>
              <Bean right>
                <img alt="Coffee Bean" src={beans1} />
              </Bean>
              <Image src={portrait} />
              <Dots src={dots} />
            </div>
          </Tilt>
        </ImageColumn>
      </Banner>
    </Section>
  );
};

export default BannerComponent;
