import React from "react";
import { Container } from "../components/layoutComponents";
import styled from "styled-components";
import drawnArrow from "../images/drawn-arrow.svg";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const H3 = styled.h3`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes.lg};
  position: relative;
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: ${({ theme }) => theme.fontSizes.xxl};
    margin-bottom: 40px;
  }

  &:before {
    content: "";
    background: ${({ theme }) => theme.colors.primary};
    position: absolute;
    width: 4px;
    height: 74px;
    left: -33px;
    top: 0;
  }
`;

const WorkIntroContainer = styled(Container)`
  padding-top: 32px;
  padding-bottom: 32px;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding-top: 96px;
    display: flex;
    padding-bottom: 96px;
    width: 100%;
  }
`;

const ContentColumn = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 50%;
    display: flex;
    align-items: center;
    padding-left: 32px;
  }
`;

const ImageColumn = styled.div`
  padding: 32px;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 0;
    width: 50%;
    display: flex;
    justify-content: center;
  }
`;

const P = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.sm};

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    max-width: 512px;
  }
`;

const Image = styled.img`
  max-width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 702px;
  }
`;

const DrawnArrow = styled.img`
  max-width: 100%;
  position: absolute;
  bottom: 20px;
  right: 35%;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 200px;
  }
`;

const WorkIntro = ({ title, content, image }) => {
  return (
    <section>
      <WorkIntroContainer lines>
        <ImageColumn>
          <Image role="presentation" loading="lazy" src={`https:${image}`} />
        </ImageColumn>
        <ContentColumn>
          <div>
            <H3>{title}</H3>
            <P>{documentToReactComponents(JSON.parse(content))}</P>
          </div>
          <DrawnArrow loading="lazy" src={drawnArrow} role="presentation" />
        </ContentColumn>
      </WorkIntroContainer>
    </section>
  );
};

export default WorkIntro;
