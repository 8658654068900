import React from 'react';
import { ThemeProvider } from 'styled-components';

const theme = {
    colors: {
        primary: '#00FFFF',
        black: '#1E1E1E',
        blackLight: '#252525',
        white: '#FFFFFF',
        mediumGray: '#E3E3E3',
        lightGray: '#E8E8E8',
        lighterGray: '#F4F4F4',
        lightestGray: '#F9F9F9',
    },
    breakpoints: {
        tablet: '768px',
        laptop: '960px',
        desktop: '1480px',
    },
    fontSizes: {
        xxs: '14px',
        xs: '16px',
        sm: '18px',
        md: '22px',
        lg: '28px',
        xl: '32px',
        xxl: '64px',
        xxxl: '80px',
        xxxxl: '106px',
    },
};

export default ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;
