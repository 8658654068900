import React, { useState } from "react";
import styled, { css } from "styled-components";
import Slider from "react-slick";
import slickNext from "../images/slick-next.svg";
import slickPrev from "../images/slick-prev.svg";

const SliderWrapper = styled.div`
  position: relative;

  .slick-slide {
    pointer-events: none;
  }

  .slick-active {
    pointer-events: auto;
  }
`;

const SlickArrow = styled.button`
  width: 64px;
  height: 64px;
  position: absolute;
  bottom: 16px;
  top: auto;
  transform: none;
  z-index: 999;
  margin-bottom: 6px;

  &:hover,
  &:active {
    img {
      transform: scale(1.1);
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    bottom: 0px;
    width: 90px;
    height: 90px;
  }

  @media (min-width: 2000px) {
    bottom: 120px;
  }

  ${({ prev }) =>
    prev
      ? css`
          left: 64px;

          @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
            left: 40%;
          }
        `
      : css`
          right: 64px;

          @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
            right: 40%;
          }
        `};

  img {
    width: 32px;
    transform: scale(1);

    @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
      width: 44px;
    }
  }

  &:before {
    content: "";
  }
`;

const Counter = styled.div`
  position: absolute;
  bottom: 44px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  font-weight: 700;
  color: #fff;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    bottom: 40px;
  }

  @media (min-width: 2000px) {
    bottom: 160px;
  }
`;

const WorkSlickSlider = ({ children }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const settings = {
    arrows: true,
    dots: false,
    draggable: false,
    fade: true,
    infinite: true,
    lazyLoad: false,
    pauseOnHover: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    swipe: false,
    afterChange: (current) => setActiveSlideIndex(current),
    nextArrow: (
      <SlickArrow next>
        <img loading="lazy" src={slickNext} />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow prev>
        <img loading="lazy" src={slickPrev} />
      </SlickArrow>
    ),
  };

  const currentSlideNumber = String(activeSlideIndex + 1).padStart(2, "0");
  const totalSlideNumber = String(children.length).padStart(2, "0");

  return (
    <SliderWrapper>
      <Counter>{`${currentSlideNumber} / ${totalSlideNumber}`}</Counter>
      <Slider {...settings}>{children}</Slider>
    </SliderWrapper>
  );
};

export default WorkSlickSlider;
