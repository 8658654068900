import React from "react";
import styled from "styled-components";
import { Container } from "./layoutComponents";
import logo from "../images/logo.svg";
import linkedin from "../images/linkedin.svg";
import dribbl from "../images/dribbl.svg";
import instagram from "../images/instagram.svg";

const FooterWrapper = styled.footer`
  display: flex;
  align-items: center;
  padding: 24px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    height: 128px;
  }
`;

const Logo = styled.img`
  width: 56px;
  height: 56px;
  cursor: pointer;
  order: -1;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    order: 0;
  }

  &:hover {
    transform: scale(1.1);
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    flex-direction: row;
    justify-content: space-between;

    > * {
      flex-grow: 1;
      flex-basis: 33.33%;
    }
  }
`;

const SocialWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > div {
    display: flex;
    justify-content: space-between;
  }
`;

const SocialIconWrapper = styled.span`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-left: 8px;
  border: 2px solid ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  img {
    width: 18px;
    height: 18px;
  }
`;

const SocialLink = styled.a`
  &:hover {
    transform: scale(1.1);
  }
`;

const CopyrightText = styled.a`
  margin: 32px 0;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    text-align: left;
    margin: 0;
  }
`;

const Footer = () => (
  <FooterWrapper>
    <Container>
      <Inner>
        <CopyrightText>
          © {new Date().getFullYear()} Ceklic Consulting Pty Ltd. All rights
          reserved.
        </CopyrightText>
        <Logo
          onClick={() => {
            document.getElementById("wrapper").scrollIntoView();
          }}
          src={logo}
        />
        <SocialWrapper>
          <div>
            <SocialLink
              target="_blank"
              href="https://www.linkedin.com/in/petar-ceklic/"
            >
              <SocialIconWrapper>
                <img loading="lazy" src={linkedin} />
              </SocialIconWrapper>
            </SocialLink>
            <SocialLink
              target="_blank"
              href="https://www.instagram.com/petar_pbg/"
            >
              <SocialIconWrapper>
                <img loading="lazy" src={instagram} />
              </SocialIconWrapper>
            </SocialLink>
            <SocialLink target="_blank" href="https://dribbble.com/petarc">
              <SocialIconWrapper>
                <img loading="lazy" src={dribbl} />
              </SocialIconWrapper>
            </SocialLink>
          </div>
        </SocialWrapper>
      </Inner>
    </Container>
  </FooterWrapper>
);

export default Footer;
