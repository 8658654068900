import React, { useMemo } from "react";
import WorkSlide from "./WorkSlide";
import styled from "styled-components";
import WorkSlickSlider from "./WorkSlickSlider";
import flattenDeep from "lodash/flattenDeep";

const Section = styled.section`
  background-color: ${({ theme }) => theme.colors.black};
`;

const Mobile = styled.div`
  display: block;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    display: none;
  }
`;

const Desktop = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    display: block;
  }
`;

const WorkCarousel = ({ workCarousel, carouselInViewPort }) => {
  const slides = useMemo(() => {
    return {
      mobile: flattenDeep([
        workCarousel.map((project) => [
          (project.carouselImagesMobile || project.carouselImages).map(
            (slide) => {
              return {
                img: slide.file.url,
                noteText: project.noteText,
                projectName: project.projectName,
                service: project.service,
                websiteUrl: project.websiteUrl,
              };
            }
          ),
        ]),
      ]),
      desktop: flattenDeep([
        workCarousel.map((project) => [
          project.carouselImages.map((slide) => {
            return {
              img: slide.file.url,
              noteText: project.noteText,
              projectName: project.projectName,
              service: project.service,
              websiteUrl: project.websiteUrl,
            };
          }),
        ]),
      ]),
    };
  }, []);

  return (
    <Section>
      <Mobile>
        <WorkSlickSlider>
          {slides.mobile.map((slide, index) => (
            <WorkSlide
              carouselInViewPort={carouselInViewPort}
              img={slide.img}
              index={index}
              noteText={slide.noteText}
              projectName={slide.projectName}
              service={slide.service}
              websiteUrl={slide.websiteUrl}
            />
          ))}
        </WorkSlickSlider>
      </Mobile>
      <Desktop>
        <WorkSlickSlider>
          {slides.desktop.map((slide, index) => (
            <WorkSlide
              carouselInViewPort={carouselInViewPort}
              img={slide.img}
              index={index}
              noteText={slide.noteText}
              projectName={slide.projectName}
              service={slide.service}
              websiteUrl={slide.websiteUrl}
            />
          ))}
        </WorkSlickSlider>
      </Desktop>
    </Section>
  );
};

export default WorkCarousel;
